import React from "react";
import { graphql } from "gatsby";
// import Img from "gatsby-image";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";

// eslint-disable-next-line no-unused-vars
import { pagesDataQuery } from "../queries/queries";

import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import {
  Table,
  TableCaption,
  TableThead,
  TableTbody,
  TableTr,
  TableTh,
  TableTd,
} from "../components/table/table";
import { Code } from "../components/code/code";
import { Donut } from "../components/donut/donut";

import "./single-test.scss";

const compareNames = (a, b) => {
  var nameA = a.node.slug.toUpperCase(); // ignore upper and lowercase
  var nameB = b.node.slug.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
};

const compareSlugs = (a, b) => {
  var nameA = a.parent.name.toUpperCase(); // ignore upper and lowercase
  var nameB = b.parent.name.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
};

const compare = (a, b) => {
  if (a.code > b.code) {
    return 1;
  }

  if (a.code < b.code) {
    return -1;
  }

  return 0;
};

const compareCount = (a, b) => {
  if (a.count > b.count) {
    return 1;
  }

  if (a.count < b.count) {
    return -1;
  }

  return 0;
};

function rankMunicipalities(currentMunicipality, pagesData, testData) {
  const municipalities = Object.values(
    pagesData.reduce((municipalities, { node: page }) => {
      const municipality = municipalities[page.group] || {
        count: 0,
        slug: page.slug,
      };

      municipalities[page.group] = municipality;

      const municipalitySibling = testData.find(datum => page.slug === datum.parent.name);
      const errorCount = municipalitySibling.data[0].code ? municipalitySibling.data.length : 0;

      municipality.count = municipality.count + errorCount;

      return municipalities;
    }, {})
  );

  const sortedMunicipalities = municipalities.sort(compareCount);

  let rank = 1;
  let lastErrorCount = 0;
  const rankedMunicipalities = sortedMunicipalities.map((item, index) => {
    if (item.count !== lastErrorCount) {
      rank = index + 1;
      lastErrorCount = item.count;
    }

    return {
      rank,
      ...item,
    };
  });

  const rankedMunicipality = rankedMunicipalities.find(datum => currentMunicipality === datum.slug);

  return { rank: rankedMunicipality.rank, countMunicipalities: sortedMunicipalities.length };
}

function parseData(pageData, pagesData, testData) {
  const sitesInGroup = pagesData.filter(obj => obj.node.group === pageData.group);
  sitesInGroup.sort(compareNames);
  const filterRegex = new RegExp(pageData.slug, "i");
  const testsInGroup = testData.filter(obj => filterRegex.test(obj.parent.name));
  testsInGroup.sort(compareSlugs);

  const totalErrors = testsInGroup.reduce((acc, cur) => {
    const addThis = cur.data[0].code === "" ? 0 : cur.data.length;
    return acc + addThis;
  }, 0);

  const sitesResultArray = testsInGroup.map((result, index) => {
    return {
      tests: result.data,
      id: sitesInGroup[index].node.id,
      slug: sitesInGroup[index].node.slug,
      name: sitesInGroup[index].node.name,
      url: sitesInGroup[index].node.testUrl,
      totalErrors,
    };
  });

  return sitesResultArray;
}

export default ({ data }) => {
  const pageData = data.allSitePage.edges[0].node.context;
  const pagesData = data.allPagesJson.edges;
  const csvTestData = data.allCsvCsv.nodes;
  const municipalityData = data.allMunicipalityInfoJson.edges[0].node;
  const testData = parseData(pageData, pagesData, csvTestData);
  const rank = rankMunicipalities(pageData.slug, pagesData, csvTestData);

  return (
    <Layout>
      <SEO title={`Resultat af tilgængelighedstest af ${pageData.name}`} />
      <div className="single-test-top">
        <div className="single-test-top__item">
          {/* <Link to="/" className="back-link">
            <FiChevronLeft />
            Gå tilbage til oversigten
          </Link> */}

          <h2>{pageData.name} Kommune</h2>

          <p>- test foretaget d. {testData[0].tests[0].date}</p>
        </div>

        <div className="single-test-top__item single-test-top__item--inline single-test-top__item--desktop">
          <Donut
            id="single-test-errors"
            title={`Grafen viser at ${pageData.name} ligger nummer ${rank.rank} ud af ${rank.countMunicipalities} kommuner`}
            firstLine={`#${rank.rank}`}
            secondLine="ud af"
            thirdLine={`${rank.countMunicipalities} kommuner`}
            thickness="15.5"
          />
          <Donut
            id="single-test-ranking"
            title={`Grafen viser at ${pageData.name} har ${testData[0].totalErrors.toLocaleString(
              "da-dk"
            )} fejl fordelt på tre sider`}
            firstLine={testData[0].totalErrors.toLocaleString("da-dk")}
            secondLine="fejl i alt"
            thirdLine="fordelt på tre sider"
            hasErrors={testData[0].totalErrors > 0}
            hasZeroErrors={testData[0].totalErrors === 0}
            thickness="15.5"
          />
          <Donut
            id="single-test-pie-chart"
            aria-describedby="source-text"
            title={`Grafen viser at ${municipalityData.totalPeopleOver65Percent}% er over 64 år ifølge ${municipalityData.sourceText}, ${municipalityData.formatUpdatedOn}`}
            firstLine={`${municipalityData.totalPeopleOver65Percent}%`}
            secondLine={`af ${municipalityData.totalPeople.toLocaleString("da-dk")}`}
            thirdLine="er over 64 år"
            percentage={municipalityData.totalPeopleOver65Percent}
            // source={`${municipalityData.sourceText}, ${municipalityData.formatUpdatedOn}`}
            thickness="13"
            showPie
          />
        </div>
      </div>

      <div className="single-test-top__item single-test-top__item--mobile">
        <ul className="bar-chart">
          <style>--placement: {`${(rank.rank / rank.countMunicipalities) * 100}%`}</style>
          <li
            className="bar-chart__item bar-chart__item--placement"
            style={{ "--placement": (rank.rank / rank.countMunicipalities) * 100 + "%" }}
          >
            <p>
              <strong>#{rank.rank}</strong>
              <span>
                Nummer {rank.rank} ud af {rank.countMunicipalities} kommuner.
              </span>
            </p>
          </li>
          <li className="bar-chart__item">
            <p>
              <strong>{testData[0].totalErrors.toLocaleString("da-dk")}</strong>
              <span>
                {testData[0].totalErrors.toLocaleString("da-dk")} fejl i alt fordelt på tre sider.
              </span>
            </p>
          </li>
          <li
            className="bar-chart__item bar-chart__item--percentage"
            style={{ "--percentage": municipalityData.totalPeopleOver65Percent + "%" }}
          >
            <p>
              <strong>{municipalityData.totalPeopleOver65Percent}%</strong>
              <span>
                {municipalityData.totalPeopleOver65Percent}% af{" "}
                {municipalityData.totalPeople.toLocaleString("da-dk")} er over 64 år.
              </span>
            </p>
          </li>
        </ul>
      </div>

      <Tabs>
        <TabList>
          {testData.map(data => (
            <Tab key={`tab-${data.id}`}>
              {pageData.name === data.name ? "Forside" : `${data.name.replace(pageData.name, "")}`}
            </Tab>
          ))}
        </TabList>

        <TabPanels>
          {testData.map(data => {
            data.tests.sort(compare);

            return (
              <TabPanel key={`tabpanel-${data.id}`}>
                <Table>
                  <TableCaption>
                    Testen på{" "}
                    <a href={`http://${data.url}`}>
                      {pageData.name} Kommunes{" "}
                      {pageData.name === data.name
                        ? "forside"
                        : `${data.name.replace(pageData.name, "").toLowerCase()}-sektion`}
                    </a>{" "}
                    gav{" "}
                    <strong>
                      {data.tests[0].code === "" ? 0 : data.tests.length.toLocaleString("da-dk")}{" "}
                      fejl
                    </strong>
                  </TableCaption>
                  <colgroup>
                    <col className="col-number" />
                    <col className="col-criteria" />
                    <col />
                    <col className="col-code" />
                    <col className="col-code" />
                  </colgroup>
                  <TableThead>
                    <TableTr>
                      <TableTh scope="col">#</TableTh>
                      <TableTh scope="col">Kriterie</TableTh>
                      <TableTh scope="col">Beskrivelse af fejlen</TableTh>
                      <TableTh scope="col">Kode hvori der er fejl</TableTh>
                      <TableTh scope="col">Sti til fejlen</TableTh>
                    </TableTr>
                  </TableThead>

                  <TableTbody>
                    {data.tests.length > 0 && data.tests[0].code !== "" ? (
                      data.tests.map((item, nestedIndex) => {
                        const criteria = item.code.split(".")[3].replace(/_/g, ".");
                        return (
                          <TableTr key={`${criteria}-${nestedIndex}`}>
                            <TableTd alignRight thText="#">
                              {nestedIndex + 1}
                            </TableTd>
                            <TableTd alignRight thText="Kriterie">
                              {criteria}
                            </TableTd>
                            <TableTd thText="Beskrivelse af fejlen" lang="en">
                              {item.message}
                            </TableTd>
                            <TableTd thText="Kode hvori der er fejl" lang="en">
                              <Code language="html">{item.context}</Code>
                            </TableTd>
                            <TableTd thText="Sti til fejlen" lang="en">
                              <Code language="css">{item.selector}</Code>
                            </TableTd>
                          </TableTr>
                        );
                      })
                    ) : (
                      <TableTr>
                        <TableTd thText="Wauw!" colSpan={5}>
                          Ingen fejl – godt gået!
                        </TableTd>
                      </TableTr>
                    )}
                  </TableTbody>
                </Table>
              </TabPanel>
            );
          })}
        </TabPanels>
      </Tabs>
    </Layout>
  );
};

export const query = graphql`
  query($path: String!, $slug: String!) {
    allSitePage(filter: { path: { eq: $path } }) {
      edges {
        node {
          context {
            group
            slug
            name
            testUrl
          }
        }
      }
    }

    allMunicipalityInfoJson(filter: { url: { eq: $slug } }) {
      edges {
        node {
          formatUpdatedOn
          sourceText
          totalPeople
          totalPeopleOver65
          totalPeopleOver65Percent
          url
        }
      }
    }

    allPagesJson {
      edges {
        node {
          ...pagesDataQueryFragment
        }
      }
    }

    allCsvCsv {
      nodes {
        data {
          code
          message
          context
          selector
          date
        }
        parent {
          ... on File {
            name
          }
        }
      }
    }
  }
`;
