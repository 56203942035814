import React from "react";
import classNames from "classnames";

import { VisuallyHiddenClassName } from "../visually-hidden/index";

import "./donut.scss";

export const Donut = ({
  percentage,
  firstLine,
  secondLine,
  thirdLine,
  title,
  source,
  showPie,
  hasErrors,
  hasZeroErrors,
  thickness = 14,
  ...props
}) => {
  return (
    <figure className={classNames("donut", { "donut--has-source": source })}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 32 32"
        className="donut__pie"
        role="img"
        aria-hidden="true"
        focusable="false"
        {...props}
      >
        <circle
          className={classNames("donut__pie-background", {
            "donut__pie-background--error": hasErrors,
            "donut__pie-background--success": hasZeroErrors,
          })}
          cx="16"
          cy="16"
          r="16"
          role="presentation"
        />
        {showPie && (
          <circle
            className="donut__pie-piece"
            cx="16"
            cy="16"
            r="16"
            fill="transparent"
            strokeWidth="32"
            strokeDasharray={`${percentage} ${100 - percentage}`}
            strokeDashoffset="25"
          />
        )}

        <circle
          className="donut__pie-text-background"
          cx="16"
          cy="16"
          r={thickness}
          role="presentation"
        />

        <g className="donut__pie-text">
          <text className="donut__pie-text-large" x="50%" y="50%" textAnchor="middle">
            {firstLine}
          </text>
          <text className="donut__pie-text-small" x="50%" y="50%" textAnchor="middle">
            {secondLine}
          </text>

          <text
            className="donut__pie-text-small donut__pie-text-small--lower"
            x="50%"
            y="50%"
            textAnchor="middle"
          >
            {thirdLine}
          </text>
        </g>
      </svg>

      <figcaption className={VisuallyHiddenClassName}>{title}</figcaption>

      {source && <div className="donut__source">{source}</div>}
    </figure>
  );
};
